* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	background: #bfbfbf;
}

.App {
	max-width: 1000px;
	margin: auto;
	padding: 1rem;
	background: #f0f0f0;
	min-height: 100vh;
}

h1 {
	margin-bottom: 1rem;
	font-size: 3rem;
	text-align: center;
}

a {
	color: #aa0000;
}

a:visited {
	color: #aa9999;
}
.upload-box {
	display: block;
	border: dashed 1px rgb(29, 161, 242);
	padding: 2rem;
	background: #ffffff;
	margin-bottom: 1rem;
}

.upload-box input {
	display: none;
}

.tweet {
	padding: 1rem;
	border: solid 1px #dfdfdf;
	margin-bottom: 1rem;
	background: #ffffff;
}

.tweet-text {
	white-space: pre-wrap;
}

.created-time {
	text-align: right;
	color: #808080;
	font-size: .8rem;
	font-style: italic;
	margin-bottom: .5rem;
}

.meta {
	margin-top: .5rem;
	font-weight: bold;
	font-size: .8rem;
	display: flex;
}

.meta div {
	margin-right: 1rem;
}

.dashboard {
	display: grid;
	position: sticky;
	top: 0;
	grid-template-columns: repeat(3, 1fr);
	margin-bottom: 1rem;
	background: #cfcfcf;
	border: solid 1px #808080;
	align-items: center;

}

.dashboard div {
	padding: .5rem;
}

.dashboard input[type="number"], .dashboard select {
	display: block;
	width: 100%;
	padding: .25rem;
}

.privacy {
	font-size: .8rem;
	margin-top: 2rem;
	border-top: dotted 1px #808080;
	padding-top: .5rem;
	text-align: center;
	font-style: italic;
}

.privacy strong {
	color: #808080;
	text-transform: uppercase;
}